import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import wrapTrack from '../../utils/wrapTrack';
import Button from '../ui/Button';
import styles from './HowSuccessIsWrittenDownloadForm.module.scss';

const STORAGE_KEY = 'downloadForm_scrollTop';
const SITE_ID = '5321427c15239a10d4b0';
const FORM_ID = 'how-success-is-written';

const getTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    return '';
  }
};

const HowSuccessIsWrittenDownloadForm: React.FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [formUrl, setFormUrl] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');

  useLayoutEffect(() => {
    const scrollTop = sessionStorage.getItem(STORAGE_KEY);

    setTimeout(() => {
      // Images in the content need time
      // to render before scrolling
      if (scrollTop != null) {
        window.scrollTo(0, parseInt(scrollTop, 10));
        setIsSubscribed(true);
      }
    }, 100);

    setTimeout(() => {
      // Multiple forms could be rendered on the page,
      // making sure they all have a chance to read the value
      sessionStorage.removeItem(STORAGE_KEY);
    }, 200);
  }, []);

  useEffect(() => {
    setCurrentUrl(
      typeof window === 'undefined' ? '' : window.document.location.toString(),
    );
  }, []);

  useEffect(() => {
    const params = new URLSearchParams({
      site_id: SITE_ID,
      form_id: FORM_ID,
      success_url: currentUrl,
    });

    setFormUrl(
      `https://customerioforms.com/forms/submit_action?${params.toString()}`,
    );
  }, [currentUrl]);

  const handleSubmit = useMemo(
    () =>
      wrapTrack(
        { label: 'how-success-is-written-download', url: currentUrl },
        () => {
          sessionStorage.setItem(STORAGE_KEY, window.scrollY.toString());
        },
      ),
    [currentUrl],
  );

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setIsSubscribed(false);
  };

  return (
    <form method="POST" action={formUrl} onSubmit={handleSubmit}>
      <div className={styles.form}>
        <input type="hidden" name="source" value="how-success-is-written" />
        <input type="hidden" name="id" value={email} />
        <input type="hidden" name="tz" value={getTimezone()} />
        <input
          className={styles.emailField}
          type="email"
          name="email"
          placeholder="Email Address"
          required
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
        />
        <Button primary small type="submit" disabled={isSubscribed}>
          Download PDF
        </Button>
        {isSubscribed ? (
          <p className={styles.sentMessage}>Email has been sent!</p>
        ) : null}
      </div>
    </form>
  );
};

export default HowSuccessIsWrittenDownloadForm;
