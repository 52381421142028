import React, { ReactNode } from 'react';
import Spacing from './Spacing';
import Text from './Text';

interface ArrowLinkProps {
  children: ReactNode;
}

const ArrowGoTo: React.SFC<ArrowLinkProps> = ({ children }) => (
  <Text bold>
    {children}
    <Spacing inline left={1}>
      <Text inline link>
        ➔
      </Text>
    </Spacing>
  </Text>
);

export default ArrowGoTo;
