import React, { ReactNode } from 'react';
import UntitledStyle from './UntitledStyle';
import Layout from './Layout';

interface MicrositeLayoutProps {
  children: ReactNode;
}

const MicrositeLayout: React.SFC<MicrositeLayoutProps> = ({ children }) => (
  <Layout>
    <UntitledStyle>{children}</UntitledStyle>
  </Layout>
);

export default MicrositeLayout;
