/* eslint-disable camelcase */
import { FixedObject, FluidObject } from 'gatsby-image';
import { Chapter, Citation, Interview } from '../types';

interface ChapterQueryResponse {
  node: {
    fields: {
      slug: string;
      chapter_number: number;
    };
    title: string;
    interviews: string[];
  };
}

type Json = string;

interface InterviewQueryResponse {
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
    summary: string;
    author_avatar: {
      childImageSharp: { fluid: FluidObject; fixed: FixedObject };
    };
    author_name: string;
    author_title: string;
    author_twitter: string;
    author_linkedin: string;
    author_preferred_social: string;
    readtime: string;
    company: string;
    citations: Citation[];
  };
  html: string;
  htmlAst: Json;
}

const fullChapters = (
  interviewData: InterviewQueryResponse[],
  chapterData: ChapterQueryResponse[],
): Chapter[] => {
  return chapterData.map((chapter) => {
    const interviews: Interview[] = chapter?.node?.interviews.flatMap((i) => {
      const interviewSlug = simplifySlug(i);
      const interview: InterviewQueryResponse | undefined = interviewData.find(
        (d) => interviewSlug === d.fields.slug,
      );

      if (interview) {
        const {
          fields: { slug },
          frontmatter: {
            title,
            summary,
            author_name,
            author_title,
            author_avatar,
            author_twitter,
            author_linkedin,
            author_preferred_social,
            readtime,
            company,
            citations,
          },
          html,
          htmlAst,
        } = interview;

        return {
          slug,
          title,
          summary,
          authorName: author_name,
          authorTitle: author_title,
          authorAvatar: author_avatar,
          authorTwitter: author_twitter,
          authorLinkedin: author_linkedin,
          authorPreferredSocial: author_preferred_social,
          readtime,
          company,
          html,
          htmlAst,
          citations,
        };
      }

      return [];
    });

    return {
      slug: chapter.node.fields.slug,
      title: chapter.node.title,
      chapterNumber: chapter.node.fields.chapter_number,
      interviews,
    };
  });
};

function simplifySlug(slug: string) {
  return slug.split('-').slice(1).join('-');
}

export default fullChapters;
