import { graphql, useStaticQuery } from 'gatsby';
import fullChapters from '../../utils/fullChapters';

const query = graphql`
  query {
    allGuidesHowSuccessIsWrittenChaptersYaml(
      sort: { fields: [fields___slug], order: ASC }
    ) {
      edges {
        node {
          fields {
            chapter_number
            slug
          }
          interviews
          title
        }
      }
    }

    allMarkdownRemark(
      filter: {
        fields: { type: { eq: "how-success-is-written" } }
        frontmatter: { hide: { ne: true } }
      }
      sort: { fields: [fileAbsolutePath], order: ASC }
    ) {
      nodes {
        frontmatter {
          author_avatar {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          author_name
          author_title
          author_twitter
          author_linkedin
          author_preferred_social
          readtime
          company
          summary
          title
          citations {
            id
            name
            title
            link
            picture {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            quoteColor
          }
        }
        fields {
          slug
        }
        html
        htmlAst
      }
    }
  }
`;

const useChapters = () => {
  const data = useStaticQuery(query);

  return fullChapters(
    data.allMarkdownRemark.nodes,
    data.allGuidesHowSuccessIsWrittenChaptersYaml.edges,
  );
};

export default useChapters;
