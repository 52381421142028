import { Link } from 'gatsby';
import React from 'react';
import logo from '../../images/logo.svg';
import ArrowGoTo from '../ui/ArrowGoTo';
import styles from './HowSuccessIsWrittenNav.module.scss';

const HowSuccessIsWrittenNav: React.FunctionComponent = () => (
  <div className={styles.nav}>
    <Link to="/">
      <img src={logo} className={styles.logo} alt="Slab" />
    </Link>
    <Link to="/">
      <ArrowGoTo>Try Slab</ArrowGoTo>
    </Link>
  </div>
);

export default HowSuccessIsWrittenNav;
