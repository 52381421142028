import { Link } from 'gatsby';
import React from 'react';
import logo from '../../images/logo.svg';
import HowSuccessIsWrittenDownloadForm from './HowSuccessIsWrittenDownloadForm';
import styles from './HowSuccessIsWrittenFooter.module.scss';

const HowSuccessIsWrittenFooter: React.FunctionComponent = () => (
  <div className={styles.footer}>
    <Link to="/">
      <img src={logo} className={styles.logo} alt="Slab" />
    </Link>
    <HowSuccessIsWrittenDownloadForm />
  </div>
);

export default HowSuccessIsWrittenFooter;
